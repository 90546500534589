import { getImageServer } from '@/utils/getImageServer';
import { getUrl } from '@/utils/getUrl';
import { logger } from '@/utils/logger';
import type { ImageLoader as NextImageLoader } from 'next/image';

export const labradorImageLoader: NextImageLoader = ({ src, width: srcSetWidth, quality = 70 }): string => {
  try {
    const isLocalImage = src.startsWith('/');

    const imageServer = getImageServer();
    const url = getUrl(src, !isLocalImage && imageServer);
    const { searchParams } = url ?? {};

    const isVideoPoster = url?.host === 'cdn.jwplayer.com';

    const width: number = Number(searchParams?.get('width'));
    const height: number = Number(searchParams?.get('height'));

    const scale = width ? srcSetWidth / width : 1;

    width && !isVideoPoster && searchParams?.set('width', `${Math.round(width * scale)}`);
    height && !isVideoPoster && searchParams?.set('height', `${Math.round(height * scale)}`);

    quality && searchParams?.set('compression', quality.toString());

    return url?.href ?? src;
  } catch (error) {
    logger.catch(error);
    return src;
  }
};
