import { ImageTheme } from '@/components/Image/theme';
import { GenericSlotFactory } from 'base/components/GenericSlot';
import NextImage from 'next/image';
import React from 'react';
import base, { type Base } from './slots/base';

export const ImageContext = React.createContext({});

const $ = GenericSlotFactory({ theme: ImageTheme, context: ImageContext });

const Base = $({ as: NextImage as Base, slot: 'base', render: base.render });
const Byline = $({ as: 'span', slot: 'byline' });
const Caption = $({ as: 'span', slot: 'caption' });
const CaptionGroup = $({ as: 'figcaption', slot: 'captionGroup' });
const Group = $({ as: 'figure', slot: 'group' });

export const Image = Object.assign(Base, {
  Byline,
  Caption,
  CaptionGroup,
  Group,
});
