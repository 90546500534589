const gridSystemBreakpoints = {
  xs: 360,
  sm: 480,
  md: 760,
  lg: 1040,
  xl: 1320,
  '2xl': 1600,
};

const maxConstrains = {
  maxContentWidth: '1320px',
  maxArticleWidth: '648px',
};

const gridSystemConfig = {
  screens: {
    xs: {
      minWidth: gridSystemBreakpoints.xs + 'px',
      ...maxConstrains,
    },
    sm: {
      minWidth: gridSystemBreakpoints.sm + 'px',
      gap: '12px',
      margin: '12px',
      ...maxConstrains,
    },
    md: {
      minWidth: gridSystemBreakpoints.md + 'px',
      gap: '18px',
      margin: '18px',
      ...maxConstrains,
    },
    lg: {
      minWidth: gridSystemBreakpoints.lg + 'px',
      gap: '24px',
      margin: '24px',
      ...maxConstrains,
    },
    xl: {
      minWidth: gridSystemBreakpoints.xl + 'px',
      ...maxConstrains,
    },
    '2xl': {
      minWidth: gridSystemBreakpoints['2xl'] + 'px',
      ...maxConstrains,
    },
  },
};

module.exports = {
  gridSystemBreakpoints,
  gridSystemConfig,
};
