import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { Image, ImageContext } from './Image';

export interface StandaloneImageProps
  extends StandaloneComponentProps<typeof Image>,
    Partial<Pick<React.ComponentProps<typeof Image>, 'alt' | 'src'>> {
  byline?: React.ReactNode;
  caption?: React.ReactNode;
  link?: OptionalLinkProps;
  isBodytextChild?: boolean;
}

export const StandaloneImage: StandaloneComponent<StandaloneImageProps> = ({
  alt,
  src,
  byline,
  caption,
  link,
  ...props
}) => {
  return (
    <ImageContext.Provider value={{ ...props?.options }}>
      <Image.Group>
        <OptionalLink content={<Image {...{ src: src!, alt: alt! }} {...props} />} {...link} />
        {(caption || byline) && (
          <Image.CaptionGroup>
            {caption && <Image.Caption>{caption}</Image.Caption>} {byline && <Image.Byline>{byline}</Image.Byline>}
          </Image.CaptionGroup>
        )}
      </Image.Group>
    </ImageContext.Provider>
  );
};
