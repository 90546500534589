import { useAppState } from '@/hooks/useAppState';
import { ComponentProps } from '@/types/component';
import { getBackendImageServer } from '@/utils/getBackendImageServer';
import { getImageServer } from '@/utils/getImageServer';
import { getUrl } from '@/utils/getUrl';
import { merge } from '@/utils/merge';
import { tw } from '@/utils/tw';
import { GenericSlotRender } from 'base/components/GenericSlot';
import { gridSystemConfig } from 'base/configs/gridSystem';
import { labradorImageLoader } from 'lib/image';
import Head from 'next/head';
import type { ImageProps as NextImageProps } from 'next/image';
import NextImage from 'next/image';
import { cloneElement, useEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { isEmpty, isString, isUndefined } from 'typesafe-utils';

const defaults = {
  placeholder: {
    mobile: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1NjgiIGhlaWdodD0iMjg5IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNTY4djI4OUgweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjI1OSIgY3k9IjE0NSIgcj0iOSIvPjxjaXJjbGUgY3g9IjI4NC4xOTUiIGN5PSIxNDUiIHI9IjkiLz48Y2lyY2xlIGN4PSIzMDkuNDAyIiBjeT0iMTQ1IiByPSI5Ii8+PC9nPjwvc3ZnPg==`,
    desktop: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDAiIGhlaWdodD0iMjA3IiBmaWxsPSJub25lIiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGZpbGw9IiNlNmU2ZTYiIGQ9Ik0wIDBoNDAwdjIwN0gweiIvPjxnIGZpbGw9IiM4ZDhkOGQiPjxjaXJjbGUgY3g9IjE3NSIgY3k9IjEwNCIgcj0iOSIvPjxjaXJjbGUgY3g9IjIwMC4xOTUiIGN5PSIxMDQiIHI9IjkiLz48Y2lyY2xlIGN4PSIyMjUuNDAyIiBjeT0iMTA0IiByPSI5Ii8+PC9nPjwvc3ZnPg==`,
  },
};

const getImageUrl = (src?: BaseProps['src']): URL | null => {
  if (isUndefined(src) || !isString(src) || isEmpty(src) || src === '/') {
    return null;
  }

  return getUrl(src, getImageServer());
};

interface BaseProps extends ComponentProps<typeof NextImage> {
  maxWidth?: number;
}

export type Base = React.ForwardRefExoticComponent<
  Partial<NextImageProps> & {
    maxWidth?: number;
    isBodytextChild?: boolean;
  }
>;

const Render: GenericSlotRender<Base> = ({ element, props: { alt, src, isBodytextChild, ...props } }) => {
  const [{ isDesktop }] = useAppState();
  const [isError, setError] = useState(false);
  const [disableLazyLoad, setDisableLazyLoad] = useState(false);

  const ref = useRef<HTMLPictureElement>(null);

  useEffectOnce(() => {
    const disableLazyLoadArea = window.innerHeight * 3;
    if (isBodytextChild && ref.current && ref.current.getBoundingClientRect().top < disableLazyLoadArea) {
      setDisableLazyLoad(true);
    }
  });

  useEffect(() => {
    setError(false);
  }, [src]);

  const placeholder = props?.placeholder || (isDesktop ? defaults.placeholder.desktop : defaults.placeholder.mobile);

  const { searchParams } = getImageUrl(src) || ({} as never);

  const dimensions = {
    width: Number(props?.width || searchParams?.get('width')) || undefined,
    height: Number(props?.width || searchParams?.get('height')) || undefined,
    scaled: {
      width: 0,
      height: 0,
    },
  };

  const scale = dimensions.width && props?.maxWidth ? Math.min(props?.maxWidth / dimensions.width, 1) : 1;

  if (scale < 1) {
    dimensions.scaled.width = Math.round(dimensions.width! * scale);
    dimensions.scaled.height = Math.round(dimensions.height! * scale);

    if (searchParams?.has('width')) {
      searchParams.set('width', String(dimensions.scaled.width));
    }

    if (searchParams?.has('height')) {
      searchParams.set('height', String(dimensions.scaled.height));
    }
  }

  const fill = props?.fill || !dimensions.width || !dimensions.height;

  const maxContentWidth = props?.maxWidth ? `${props?.maxWidth}px` : gridSystemConfig.screens.lg.maxContentWidth;
  const sizes = `(max-width: ${maxContentWidth}) 100vw, ${maxContentWidth}`;

  const dimensionsProps = fill
    ? null
    : {
        width: dimensions.scaled.width || dimensions.width,
        height: dimensions.scaled.height || dimensions.height,
      };

  if (isString(src) && src.startsWith(getBackendImageServer())) props.referrerPolicy = 'no-referrer';

  const resolvedProps = merge(
    {
      alt,
      fill,
      loader: labradorImageLoader,
      onError: () => setError(true),
      placeholder,
      sizes,
      src: isError ? placeholder : src,
      priority: disableLazyLoad,
      ...dimensionsProps,
    },
    props,
  );
  return (
    <>
      <Head>
        <link rel="preload" as="image" href={placeholder} />
      </Head>
      <picture
        data-fill={fill ? '' : undefined}
        ref={ref}
        className={tw.merge(resolvedProps.className, fill && 'relative')}
        style={resolvedProps.style}
      >
        {cloneElement(element, resolvedProps)}
      </picture>
    </>
  );
};

export default {
  render: Render,
};
